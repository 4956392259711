// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landing-ai-index-js": () => import("./../../../src/templates/landing-ai/index.js" /* webpackChunkName: "component---src-templates-landing-ai-index-js" */),
  "component---src-templates-landing-tne-index-js": () => import("./../../../src/templates/landing-tne/index.js" /* webpackChunkName: "component---src-templates-landing-tne-index-js" */),
  "component---src-templates-legal-gar-index-js": () => import("./../../../src/templates/legal-gar/index.js" /* webpackChunkName: "component---src-templates-legal-gar-index-js" */),
  "component---src-templates-legal-index-js": () => import("./../../../src/templates/legal/index.js" /* webpackChunkName: "component---src-templates-legal-index-js" */),
  "component---src-templates-pilot-index-js": () => import("./../../../src/templates/pilot/index.js" /* webpackChunkName: "component---src-templates-pilot-index-js" */),
  "component---src-templates-pricing-index-js": () => import("./../../../src/templates/pricing/index.js" /* webpackChunkName: "component---src-templates-pricing-index-js" */),
  "component---src-templates-student-index-js": () => import("./../../../src/templates/student/index.js" /* webpackChunkName: "component---src-templates-student-index-js" */)
}

